<script>
import dietPlanBase from './dietPlanBase'


export default {
    components: {dietPlanBase},
    props: {},
    page: {title: 'Διατροφικά πλάνα'},
    data() {
        return {
            edits: {
                isEdited: false,
                isFuncEdited: false
            }
        }
    }
}

</script>
<template>
    <div id="dp-view-layout">
        <diet-plan-base :edits="edits"></diet-plan-base>
    </div>
</template>
